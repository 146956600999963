
import MSimpleDropdown from '@/components/utils/input/dropdowns/MSimpleDropdown.vue'
import MSimpleDropdownButton from '@/components/utils/input/dropdowns/MSimpleDropdownButton.vue'
import MSimpleDropdownOption from '@/components/utils/input/dropdowns/MSimpleDropdownOption.vue'
import {useManagersStore} from '@/store/managers/ManagerStore'
import {computed, defineComponent, onBeforeMount, onMounted, ref, unref as v, watchEffect} from 'vue'

export default defineComponent({
  components: {MSimpleDropdown, MSimpleDropdownButton, MSimpleDropdownOption},
  emits: {
    onManager: (uid: unknown) => typeof uid === 'string'
  },
  props: {
    initialManagerUID: {
      type: String,
      required: false
    }
  },
  setup(props, {emit}) {
    const managersStore = useManagersStore()
    const managersState = managersStore.getState()
    const managers = computed(() => managersState.managers)
    const selected = ref<string | null>(null)
    onBeforeMount(() => {
      if (props.initialManagerUID) selected.value = props.initialManagerUID
    })
    const selectedManager = computed(() => v(managers).find(({uid}) => uid === v(selected)))
    const isDropdown = ref(false)
    const dropDownId = 'managers-list-dd'
    const buttonText = computed(() => {
      const manager = v(selectedManager)
      return manager ? `${manager.name} ${manager.email}` : ''
    })
    const title = computed(() => selectedManager.value ? 'Haldaja' : 'Palun vali haldaja')
    const setIsDrowdown = (newValue: boolean) => (isDropdown.value = newValue)
    onMounted(async () => {
      await managersStore.setManagers(false)
      window.addEventListener('click', (e: MouseEvent) => {
        if (v(isDropdown)) {
          const target = e.target as HTMLDivElement
          if (!target?.closest(`#${dropDownId}`)) setIsDrowdown(false)
        }
      })
    })
    watchEffect(() => {
      if (selected.value) emit('onManager', selected.value)
    })
    return {isDropdown, dropDownId, setIsDrowdown, managers, selected, buttonText, title}
  }
})
