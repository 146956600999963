<template>
  <div class="flex flex-col">
    <div class="align-middle inline-block min-w-full">
      <div class="shadow-custom overflow-x-auto overflow-y-hidden rounded-xl">
        <table class="min-w-full divide-y divide-gray-200">
          <slot></slot>
        </table>
      </div>
    </div>
  </div>
</template>
