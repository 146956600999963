
import {computed, defineComponent, onBeforeMount, PropType, ref} from "vue";
import InputField from "@/components/utils/input/InputField.vue";
import {InputFieldType} from "@/types";
import {AddUserInput, GetUserResult} from "@/shared/types";

export default defineComponent({
  components: {InputField},
  emits: {
    onUser: (payload: AddUserInput | Omit<AddUserInput, 'password'>) => payload,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    initialUser: {
      type: Object as PropType<GetUserResult>,
      required: false
    }
  },
  setup(props, {emit}) {


    const name = ref<string | null>(null);
    const email = ref<string | null>(null);
    const phone = ref<string | null>(null);

    onBeforeMount(() => {
      if (props.initialUser) {
        name.value = props.initialUser.name || null
        email.value = props.initialUser.email || null
        phone.value = props.initialUser.phone || null
      }
    })

    const isPhoneValid = computed(() => {
      if (!phone.value) return true;
      const regEx = /^\+[1-9]\d{1,14}$/;
      return regEx.test(phone.value);
    });

    const password = ref<string | null>(null);
    const passwordRepeat = ref<string | null>(null);
    const passwordValid = computed(() => {
      if (!password.value) return true;
      else return password.value.length >= 6;
    });

    const passwordRepeatValid = computed(() => {
      if (!password.value) return true;
      else
        return !passwordValid.value || password.value === passwordRepeat.value;
    });

    const isString = (input: unknown): input is string => {
      return typeof input === "string";
    };

    const isFormValid = computed(
        () =>
            name.value?.length &&
            email.value?.length &&
            phone.value?.length &&
            (props.editMode || (password.value?.length &&
                passwordValid.value &&
                passwordRepeat.value?.length &&
                passwordRepeatValid.value))
    );

    const userInput = computed<AddUserInput | Omit<AddUserInput, 'password'> | undefined>(() => {
      if (
          isString(name.value) &&
          isString(email.value) &&
          isString(phone.value) &&
          (props.editMode || isString(password.value))
      ) {
        return {
          name: name.value,
          email: email.value,
          phone: phone.value,
          password: password.value,
        };
      }
      return undefined;
    });

    const onUserAdd = () => {
      if (!isFormValid.value || !userInput.value) return
      emit("onUser", userInput.value);
    };
    return {
      InputFieldType,
      name,
      email,
      phone,
      isPhoneValid,
      password,
      passwordRepeat,
      passwordValid,
      passwordRepeatValid,
      isFormValid,
      onUserAdd,
    };
  },
});
