
import ButtonAdd from "@/components/utils/buttons/ButtonAdd.vue";
import AddClinicModal from "@/components/clinics/addclinic/AddClinicModal.vue";
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: { ButtonAdd, AddClinicModal },
  setup() {
    const addClinicModal = ref(false);
    return { addClinicModal };
  },
});
