
import ButtonAdd from "@/components/utils/buttons/ButtonAdd.vue";
import { defineComponent, ref } from "vue";
import AddManagerModal from "./AddManagerModal.vue";

export default defineComponent({
  components: { ButtonAdd, AddManagerModal },
  props: {
    color: {
      type: String,
      default: "lightgreen",
    },
  },
  setup() {
    const addManagerModal = ref(false);
    return { addManagerModal };
  },
});
