
import {defineComponent} from 'vue'
import MDropDownMenuTransition from './MDropDownMenuTransition.vue'
import MSimpleDropdownOption from "@/components/utils/input/dropdowns/MSimpleDropdownOption.vue";

export default defineComponent({
  components: {MSimpleDropdownOption, MDropDownMenuTransition},
  props: {
    open: {
      type: Boolean,
      required: true
    },
    fixedHeight: {
      type: Boolean,
      default: true

    },
    datePicker: {
      type: Boolean,
      default: false
    }
  }
})
