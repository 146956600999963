
import {useClinicsStore} from '@/store/clinics/ClinicsStore'
import {computed, defineComponent} from 'vue'
import BaseTableBodyWrapper from '../utils/tables/BaseTableBodyWrapper.vue'
import ClinicsListItem from './ClinicsListItem.vue'

export default defineComponent({
  components: {ClinicsListItem, BaseTableBodyWrapper},
  setup() {
    const clinicsStore = useClinicsStore()
    const clinicsState = clinicsStore.getState()
    const activeClinicUID = computed(() => clinicsState.activeClinicUID)
    const allClinics = computed(() => clinicsState.clinics)
    const clinics = computed(() => activeClinicUID.value ? allClinics.value.filter(clinic => clinic.uid === activeClinicUID.value) : allClinics.value)
    return {clinics}
  }
})
