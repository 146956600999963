import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex justify-end" }
const _hoisted_2 = {
  type: "submit",
  class: "font-roboto inline-flex items-center px-4 py-2 border border-transparent text-sm font-normal rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-0 focus:ring-blue-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputField = _resolveComponent("InputField")!

  return (_openBlock(), _createElementBlock("form", {
    class: "space-y-4",
    onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onUserAdd && _ctx.onUserAdd(...args)), ["prevent"]))
  }, [
    _createVNode(_component_InputField, {
      label: "Nimi",
      fieldId: "username",
      isAutocomplete: false,
      required: "",
      value: _ctx.name,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event))
    }, null, 8, ["value"]),
    _createVNode(_component_InputField, {
      label: "E-mail",
      fieldId: "email",
      isAutocomplete: false,
      required: "",
      inputType: _ctx.InputFieldType.EMAIL,
      value: _ctx.email,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event))
    }, null, 8, ["inputType", "value"]),
    _createVNode(_component_InputField, {
      label: "Telefon",
      fieldId: "userPhone",
      isAutocomplete: false,
      required: "",
      value: _ctx.phone,
      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.phone) = $event)),
      valid: _ctx.isPhoneValid,
      notValidMessage: 'Vigane telefoni number, (+372...)'
    }, null, 8, ["value", "valid", "notValidMessage"]),
    (!_ctx.editMode)
      ? (_openBlock(), _createBlock(_component_InputField, {
          key: 0,
          label: "Parool",
          fieldId: "password",
          isAutocomplete: false,
          required: !_ctx.editMode,
          inputType: _ctx.InputFieldType.PASSWORD,
          valid: _ctx.passwordValid,
          notValidMessage: 'Parool peab olema vähemalt 6 tähemärki pikk',
          value: _ctx.password,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event))
        }, null, 8, ["required", "inputType", "valid", "value"]))
      : _createCommentVNode("", true),
    (!_ctx.editMode)
      ? (_openBlock(), _createBlock(_component_InputField, {
          key: 1,
          label: "Parool uuesti",
          fieldId: "passwordRepeat",
          isAutocomplete: false,
          required: !_ctx.editMode,
          inputType: _ctx.InputFieldType.PASSWORD,
          valid: _ctx.passwordRepeatValid,
          notValidMessage: 'Paroolid ei kattu',
          value: _ctx.passwordRepeat,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.passwordRepeat) = $event))
        }, null, 8, ["required", "inputType", "valid", "value"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.editMode ? 'Muuda' : 'Salvesta'), 1)
    ])
  ], 32))
}