
import {ClinicDto} from '@/shared/types'
import {useManagersStore} from '@/store/managers/ManagerStore'
import {computed, defineComponent, PropType, ref} from 'vue'
import EditClinicModal from "@/components/clinics/EditClinicModal.vue";
import {useUserStore} from "@/store/user/UserStore";
import BaseTableEditDeleteButtons from "@/components/utils/tables/BaseTableEditDeleteButtons.vue";
import AreYouSureModal from "@/components/utils/modal/AreYouSureModal.vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import CopyButton from "@/components/utils/buttons/CopyButton.vue";
import {useWorkersStore} from "@/store/workers/WorkersStore";

export default defineComponent({
  components: {CopyButton, AreYouSureModal, BaseTableEditDeleteButtons, EditClinicModal},
  props: {
    clinic: {
      type: Object as PropType<ClinicDto>,
      required: true
    }
  },
  setup(props) {
    const managersStore = useManagersStore()
    const userStore = useUserStore()
    const clinicsStore = useClinicsStore()
    const workersStore = useWorkersStore()

    const managersState = managersStore.getState()
    const userState = userStore.getState()
    const workersState = workersStore.getState()

    const isAdmin = computed(() => userState.admin)
    const isSuperAdmin = computed(() => userState.superAdmin)
    const manager = computed(() => managersState.managers.find((manager) => manager.uid === props.clinic.managerUID))

    const activeWorkers = computed(() => workersState.workers.filter(worker => worker.active && worker.clinicUID === props.clinic.uid))
    const activeWorkersCount = computed(() => activeWorkers.value.length)

    const editClinicModal = ref(false)

    const deleteClinic = async () => {
      await clinicsStore.deleteClinic({clinicUID: props.clinic.uid})
    }

    const deleteClinicModal = ref(false)
    const onDeleteModalClose = async (action: boolean) => {
      if (action) await deleteClinic()
      deleteClinicModal.value = false
    }
    return {manager, editClinicModal, isAdmin, isSuperAdmin, deleteClinicModal, onDeleteModalClose, activeWorkersCount}
  }
})
