
import { defineComponent, ref } from 'vue'
import ModalWrapper from '@/components/utils/modal/ModalWrapper.vue'
import NewUserForm from '@/components/forms/NewUserForm.vue'
import { AddUserInput } from '@/shared/types'
import { useManagersStore } from '@/store/managers/ManagerStore'
export default defineComponent({
  components: { ModalWrapper, NewUserForm },
  emits: ['onClose'],
  setup(_, { emit }) {
    const managersStore = useManagersStore()
    const isOpen = ref(true)
    const addManager = async (manager: AddUserInput) => {
      await managersStore.addManager(manager)
      emit('onClose')
    }

    return { isOpen, addManager }
  }
})
