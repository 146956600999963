import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white" }
const _hoisted_2 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900" }
const _hoisted_3 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_4 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_5 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_6 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_7 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_8 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_9 = { class: "h-6 w-20 rounded border border-gray-100 bg-gray-100 inline-flex justify-around" }
const _hoisted_10 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_11 = {
  key: 0,
  class: "px-6 py-4 bg-white whitespace-nowrap text-right text-sm font-medium cursor-pointer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyButton = _resolveComponent("CopyButton")!
  const _component_BaseTableEditDeleteButtons = _resolveComponent("BaseTableEditDeleteButtons")!
  const _component_AreYouSureModal = _resolveComponent("AreYouSureModal")!
  const _component_EditClinicModal = _resolveComponent("EditClinicModal")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.clinic.name), 1),
    _createElementVNode("td", _hoisted_3, [
      _createVNode(_component_CopyButton, {
        content: _ctx.clinic.uid
      }, null, 8, ["content"])
    ]),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.clinic.radiationPracticeLicenseHolder), 1),
    _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.manager?.name), 1),
    _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.manager?.email), 1),
    _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.manager?.phone), 1),
    _createElementVNode("td", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.activeWorkersCount) + "/" + _toDisplayString(_ctx.clinic.usersCount), 1)
    ]),
    _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.clinic.cabinetsUID.length), 1),
    (_ctx.isAdmin)
      ? (_openBlock(), _createElementBlock("td", _hoisted_11, [
          _createVNode(_component_BaseTableEditDeleteButtons, {
            onOnEdit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editClinicModal = true)),
            onOnDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteClinicModal = true)),
            "show-delete": _ctx.isSuperAdmin,
            "show-edit": _ctx.isAdmin
          }, null, 8, ["show-delete", "show-edit"]),
          (_ctx.deleteClinicModal)
            ? (_openBlock(), _createBlock(_component_AreYouSureModal, {
                key: 0,
                onOnAction: _ctx.onDeleteModalClose
              }, null, 8, ["onOnAction"]))
            : _createCommentVNode("", true),
          (_ctx.editClinicModal)
            ? (_openBlock(), _createBlock(_component_EditClinicModal, {
                key: 1,
                onOnClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editClinicModal = false)),
                clinic: _ctx.clinic
              }, null, 8, ["clinic"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}