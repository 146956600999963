import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClinicListHeader = _resolveComponent("ClinicListHeader")!
  const _component_ClinicListBody = _resolveComponent("ClinicListBody")!
  const _component_BaseTableWrapper = _resolveComponent("BaseTableWrapper")!

  return (_openBlock(), _createBlock(_component_BaseTableWrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_ClinicListHeader),
      _createVNode(_component_ClinicListBody)
    ]),
    _: 1
  }))
}