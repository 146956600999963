
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  PropType,
  ref,
} from "vue";
import InputField from "@/components/utils/input/InputField.vue";
import { InputFieldType } from "@/types";
import ManagersDropdown from "../managers/utils/ManagersDropdown.vue";
import { AddClinicInput } from "@/shared/types";
import AlternativeManagersDropdown from "@/components/managers/utils/AlternativeManagersDropdown.vue";

export default defineComponent({
  components: {AlternativeManagersDropdown, InputField, ManagersDropdown },
  emits: {
    onClinic: (clinic: unknown) => !!clinic,
  },
  props: {
    clinic: {
      type: Object as PropType<AddClinicInput>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const name = ref<string | null>(null);
    const radiationPracticeLicenseHolder = ref<string | null>(null);
    const managerUID = ref<string | null>(null);
    const alternativeManagerUID = ref<string | null>(null);
    const usersCount = ref<number | null>(null);
    const licenseNos = ref<string[]>([""]);

    onBeforeMount(() => {
      if (props.clinic) {
        name.value = props.clinic.name;
        radiationPracticeLicenseHolder.value =
          props.clinic.radiationPracticeLicenseHolder;
        managerUID.value = props.clinic.managerUID;
        alternativeManagerUID.value = props.clinic.alternativeManagerUID;
        usersCount.value = props.clinic.usersCount;
        licenseNos.value = [...props.clinic.licenseNos];
      }
    });

    const showAddNewLicenceNoButton = computed(
      () =>
        !!licenseNos.value.length &&
        !!licenseNos.value[licenseNos.value.length - 1].length
    );
    const addNewLicenceNo = () =>
      showAddNewLicenceNoButton.value ? licenseNos.value.push("") : undefined;
    const removeLicenceNo = (index: number) =>
      licenseNos.value.splice(index, 1);
    const onClinicAdd = async () => {
      if (
        typeof name.value === "string" &&
        typeof radiationPracticeLicenseHolder.value === "string" &&
        typeof usersCount.value === "number" &&
        typeof managerUID.value === "string" &&
        licenseNos.value &&
        licenseNos.value.length
      ) {
        const clinic: AddClinicInput = {
          name: name.value,
          radiationPracticeLicenseHolder: radiationPracticeLicenseHolder.value,
          managerUID: managerUID.value,
          alternativeManagerUID: alternativeManagerUID.value,
          usersCount: usersCount.value,
          licenseNos: licenseNos.value,
        };
        // TODO: Error
        emit("onClinic", clinic);
      }
    };

    return {
      name,
      radiationPracticeLicenseHolder,
      InputField,
      InputFieldType,
      usersCount,
      onClinicAdd,
      managerUID,
      alternativeManagerUID,
      licenseNos,
      showAddNewLicenceNoButton,
      addNewLicenceNo,
      removeLicenceNo,
    };
  },
});
