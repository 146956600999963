
import { useUserStore } from "@/store/user/UserStore";
import { computed, defineComponent } from "vue";
export default defineComponent({
  
  setup() {
    const userStore = useUserStore()
    const userState = userStore.getState()
    const isAdmin = computed(() => userState.admin)
    return {
      isAdmin
    }
  }
})
