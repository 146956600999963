
import { defineComponent } from 'vue'
import ClinicListHeader from './ClinicListHeader.vue'
import ClinicListBody from './ClinicListBody.vue'
import BaseTableWrapper from '../utils/tables/BaseTableWrapper.vue'

export default defineComponent({
  components: { BaseTableWrapper, ClinicListHeader, ClinicListBody },
  setup() {
    return {}
  }
})
