
import AddClinic from "@/components/clinics/addclinic/AddClinic.vue";
import ClinicsList from "@/components/clinics/ClinicsList.vue";
import AddManager from "@/components/managers/addmanager/AddManager.vue";
import {useUserStore} from "@/store/user/UserStore";

import {defineComponent, onMounted} from "vue";
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {useManagersStore} from "@/store/managers/ManagerStore";
import {useWorkersStore} from "@/store/workers/WorkersStore";

export default defineComponent({
  components: {AddClinic, AddManager, ClinicsList},
  setup() {
    const userStore = useUserStore();
    const userState = userStore.getState();
    const clinicsStore = useClinicsStore();
    const managersStore = useManagersStore();
    const workersStore = useWorkersStore()
    onMounted(async () => {
      await Promise.all([
        managersStore.setManagers(false),
        clinicsStore.setClinics(false),
        workersStore.setWorkers(false)
      ]);
    });
    return {userState};
  },
});
