
import { computed, defineComponent, unref as v } from 'vue'
import CircleLoader from '@/components/utils/loaders/CircleLoader.vue'
import { LoaderColor } from '@/types'

export default defineComponent({
  components: { CircleLoader },
  props: {
    open: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const disabledClasses = computed(() => (props.disabled ? ['cursor-not-allowed'] : ['']))

    const loadingClasses = computed(() => (props.loading ? ['cursor-wait'] : ['']))
    const loadingOrDisabledClasses = computed(() => {
      const classes: string[] = props.loading || props.disabled
        ? ['bg-gray-100', 'text-gray-400']
        : [
          'bg-white',
          'focus:ring-1',
          'focus:ring-blue-500',
          'focus:ring-blue',
          'cursor-pointer',
        ]
      if (props.isSelected) {
        classes.push(...['py-2'])
      } else {
        classes.push(...['py-5'])
      }
      return classes
    }
    )

    const dynamiClasses = computed(() => [...v(disabledClasses), ...v(loadingClasses), ...v(loadingOrDisabledClasses)])
    return { LoaderColor, dynamiClasses }
  }
})
