
import {computed, defineComponent, PropType, ref} from 'vue'
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {AddClinicInput, ClinicDto} from "@/shared/types";
import ModalWrapper from "@/components/utils/modal/ModalWrapper.vue";
import ClinicForm from "@/components/forms/ClinicForm.vue";

export default defineComponent({
  name: "EditClinicModal",
  components: {ClinicForm, ModalWrapper},
  emits: ['onClose'],
  props: {
    clinic: {
      type: Object as PropType<ClinicDto>,
      required: true
    }
  },
  setup(props, {emit}) {
    const isOpen = ref(true)
    const clinicStore = useClinicsStore()
    const clinicInput = computed<AddClinicInput>(() => ({
      name: props.clinic.name,
      radiationPracticeLicenseHolder: props.clinic.radiationPracticeLicenseHolder,
      managerUID: props.clinic.managerUID,
      alternativeManagerUID: props.clinic.alternativeManagerUID,
      usersCount: props.clinic.usersCount,
      licenseNos: props.clinic.licenseNos
    }))
    const onClinicEdit = async (clinic: AddClinicInput) => {
      await clinicStore.editClinic({uid: props.clinic.uid, ...clinic})
      emit('onClose')
    }
    return {isOpen, onClinicEdit, clinicInput}
  }
})
