
import {defineComponent, ref} from 'vue'
import ModalWrapper from '@/components/utils/modal/ModalWrapper.vue'
import ClinicForm from '@/components/forms/ClinicForm.vue'
import {useClinicsStore} from "@/store/clinics/ClinicsStore";
import {AddClinicInput} from "@/shared/types";

export default defineComponent({
  components: {ModalWrapper, ClinicForm},
  emits: ['onClose'],
  setup() {
    const isOpen = ref(true)
    const clinicStore = useClinicsStore()
    const onClinicAdd = async (clinic: AddClinicInput) => {
      await clinicStore.addClinic(clinic)
      isOpen.value = false
    }
    return {isOpen, onClinicAdd}
  }
})
